import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCopyright} from "@fortawesome/free-regular-svg-icons";

class Footer extends React.Component{

    render() {
        const FooterMain = styled.div`
            background-color: #111;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
        `

        const SocialIcons = styled.div`
            .social_icon {
                font-size: 15px;
                color: #555;
                margin: 0 5px;
                cursor: pointer;
                transition: .5s;
                &:hover {
                    color: #04e5e5;
                }
            }
        `
        return (
            <FooterMain>
                <SocialIcons>
                    <span className="social_icon">MultiByte Digital Agency <FontAwesomeIcon icon={faCopyright} className="social_icon" />{new Date().getFullYear()}</span>
                </SocialIcons>
            </FooterMain>
        )
    }
}
export default Footer